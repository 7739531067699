import { useEffect, useState } from 'react';

import { getSiteConfig } from './siteconfig.service';

import Button from './button';

function App() {

	const [siteConfig, setSiteConfig] = useState(undefined);

	useEffect(() => {
		if (process.env.REACT_APP_TESTING_URL) {
			setSiteConfig(getSiteConfig(process.env.REACT_APP_TESTING_URL));
		} 
		else {
			setSiteConfig(getSiteConfig(window.location.href));
		}
	}, [])
	
	

	const getLandingPage = () => {
		console.log(siteConfig)
		if (siteConfig !== undefined) {
			return (
				<div id="main-container" style={{ backgroundImage: `url(${siteConfig.style.backgroundImage})` }}>
					<div id="main" style={{borderColor: siteConfig.style.outlineColor}}>
						<img className="main-logo" src={siteConfig.style.mainLogo} alt="Company Logo"/>
						<h1>{siteConfig.title}</h1>
						
						<div id="content">
							{siteConfig.subheadingText}
							{siteConfig.locations.map((location, index) => {
								return <Button 
											key={index} 
											siteUrl={location.url} 
											text={location.name} 
											backgroundColor={siteConfig.style.buttonColor}
										/>
							})}
						</div>
					</div>
				</div>
			)
		}
		else {
			return (
				<div className='error-page'>
					<iframe src="https://404.ridelinks.com" title="404 Not Found"></iframe>
				</div>
			)
		}
	}

  	return (
		<>
		{getLandingPage()}
		</>
		
  	);
}

export default App;
