import configs from './config.json';

function getSiteConfig(siteUrl){
    try {
        let siteConfig = undefined;

        for (let [key, tmpConfig] of Object.entries(configs)) {
            if (siteUrl.includes(tmpConfig.landingPageUrl)) {
                siteConfig = tmpConfig;
            }
        }
        return siteConfig;

    } catch (error) {
        console.log(error);
        return undefined;
    }
}

function parseLocations(config) {
    
}

export {
    getSiteConfig
}