import './styles.css';

function Button(props) {

    const siteUrl = props.siteUrl;
    const text = props.text;
    const backgroundColor = props.backgroundColor;

    return (
        <a className="location-link" href={siteUrl} style={{backgroundColor: backgroundColor}}>{text}</a> 
    );
};

export default Button;